import React, { useEffect, useState } from 'react'
import { getCollaborator } from '../redux/appSlices/collaborator/collaboratorSlice'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckCollabIfLockedMutation } from '../redux/appSlices/collaborator/collaboratorApiSlice'
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri'
import { toast } from 'react-toastify'

const test = {
    "status": "success",
    "data": {
        "collaborator": {
            "_id": "63c01d6c1cacf0ea1dc64f19",
            "backoffice_id": {
                "_id": "63c0ac5613804bb328dbdc86",
                "name": "Popcard Team",
                "logo": "368ac302e1e19132c65d09ad762fab43.png",
                "user": "63c0ac5713804bb328dbdc87",
                "show_onboarding_name": false,
                "onboarding_name": "",
                "restrict_register_email_ext": false,
                "register_email_ext": "",
                "restrict_company_name": false,
                "company_name": {
                    "en": "",
                    "fr": "Popcard",
                    "_id": "65c565c9ce0f51937eddccde"
                },
                "restrict_contact_email_ext": false,
                "contact_email_ext": "",
                "language": "fr",
                "show_company_name": true,
                "show_website": true,
                "restrict_website": false,
                "website": "www.getpopcard.com",
                "cover": {
                    "url": "",
                    "x": 0,
                    "y": 0,
                    "zoom": 100,
                    "_id": "65c565c9ce0f51937eddccdf"
                },
                "show_address": true,
                "form_structure": {
                    "active": true,
                    "_id": "65c565c9ce0f51937eddcce0",
                    "fields": [],
                    "createdAt": "2024-02-08T23:37:45.133Z",
                    "updatedAt": "2024-02-08T23:37:45.133Z"
                },
                "restrict_address": false,
                "address": "",
                "restrict_products": false,
                "show_about": true,
                "restrict_about": false,
                "about": {
                    "en": "",
                    "fr": "",
                    "_id": "65c565c9ce0f51937eddcce1"
                },
                "restrict_socials": false,
                "show_appointment": true,
                "appointment": "",
                "restrict_appointment": false,
                "restrict_links": false,
                "restrict_images": false,
                "restrict_videos": false,
                "show_socials": true,
                "show_links": true,
                "show_images": true,
                "show_videos": true,
                "show_products": true,
                "socials": [],
                "links": [],
                "images": [],
                "videos": [],
                "products": [],
                "collaborator_identification": 0,
                "procpect_identification": 1,
                "createdAt": "2023-01-13T01:56:54.674Z",
                "updatedAt": "2024-02-01T13:00:06.622Z",
                "__v": 0,
                "bricks": {
                    "Dashboard": {
                        "path": "/",
                        "icon": "dashboard.svg",
                        "label": "Dashboard",
                        "isAllowed": true
                    },
                    "Settings": {
                        "path": "/setting",
                        "icon": "setting.svg",
                        "label": "Paramétrage",
                        "isAllowed": true
                    },
                    "Collaborators": {
                        "path": "/collaborators",
                        "icon": "collaborators.svg",
                        "label": "Collaborateurs",
                        "isAllowed": true
                    },
                    "LiveStats": {
                        "path": "/live-stats",
                        "icon": "live.svg",
                        "label": "Live Stats",
                        "isAllowed": true
                    },
                    "Identifications": {
                        "path": "/identify",
                        "icon": "identi.svg",
                        "label": "Contacts identifiés",
                        "isAllowed": true
                    },
                    "Orders": {
                        "path": "/orders",
                        "icon": "sopping.svg",
                        "label": "Commandes",
                        "isAllowed": false
                    }
                }
            },
            "team_id": {
                "_id": "63c0ac5613804bb328dbdc86",
                "name": "Popcard Team",
                "logo": "image-1709246248876-605.png",
                "backoffice_id": "63c0ac5613804bb328dbdc86",
                "show_onboarding_name": false,
                "onboarding_name": "",
                "restrict_register_email_ext": false,
                "register_email_ext": "",
                "restrict_company_name": false,
                "company_name": {
                    "en": "",
                    "fr": "Popcard",
                    "_id": "65c565e78e4f5e5c0c934a66"
                },
                "restrict_contact_email_ext": false,
                "contact_email_ext": "",
                "language": "fr",
                "show_company_name": true,
                "show_website": true,
                "restrict_website": false,
                "website": "www.getpopcard.com",
                "cover": {
                    "url": "",
                    "x": 0,
                    "y": 0,
                    "zoom": 100,
                    "_id": "65c565e78e4f5e5c0c934a67"
                },
                "show_address": true,
                "form_structure": {
                    "fields": [],
                    "active": true,
                    "_id": "65c565e78e4f5e5c0c934a68",
                    "createdAt": "2024-02-29T22:37:31.326Z",
                    "updatedAt": "2024-02-29T22:37:31.326Z"
                },
                "restrict_address": false,
                "address": "",
                "restrict_products": false,
                "show_about": true,
                "restrict_about": false,
                "about": {
                    "en": "",
                    "fr": "",
                    "_id": "65e1072b5f860aaeee073ca1"
                },
                "restrict_socials": false,
                "show_appointment": true,
                "appointment": "",
                "restrict_appointment": false,
                "restrict_links": false,
                "restrict_images": false,
                "restrict_videos": false,
                "show_socials": true,
                "show_links": true,
                "show_images": true,
                "show_videos": true,
                "show_products": true,
                "socials": [
                    {
                        "url": "",
                        "identifier": "69d54329-400d-4654-a95b-ad22dfc82089",
                        "social_id": {
                            "_id": "637235ad60136a9a3e26f78e",
                            "label": "Linkedin",
                            "icon": "linkedin.svg",
                            "notoriety_order": 5,
                            "__v": 0
                        },
                        "is_admin": true,
                        "checkSocial": false,
                        "_id": "65e1072b5f860aaeee073ca2"
                    },
                    {
                        "url": "youtube.com",
                        "identifier": "cc124b22-5661-469b-b2d1-944384f94b0c",
                        "social_id": {
                            "_id": "637235ac60136a9a3e26f783",
                            "label": "Youtube",
                            "icon": "youtube.svg",
                            "notoriety_order": 2,
                            "__v": 0
                        },
                        "is_admin": true,
                        "checkSocial": false,
                        "_id": "65e1072b5f860aaeee073ca3"
                    }
                ],
                "links": [],
                "images": [],
                "videos": [],
                "products": [],
                "collaborator_identification": 0,
                "procpect_identification": 1,
                "createdAt": "2023-01-13T01:56:54.674Z",
                "updatedAt": "2024-02-29T22:37:31.326Z",
                "__v": 0
            },
            "first_name": "TEest Otman",
            "last_name": "HARRAK",
            "collaborator_id": "4gHjS9vUVweHTLydDlJRas20k",
            "custom_form": false,
            "profile_activated": true,
            "contact_locked": false,
            "slug": "",
            "about": {
                "en": "After Pip Pip Yalah, the fight for ecology 🌳 but above all for tech made in 🇲🇦 continues!\nI co-founded Popcard to provide professionals with an alternative to paper business cards but above all to boost their business.\nPopcard helps you centralize your digital universe, manage your commercial activity and control your brand image.\n\nContact me for more information 👋🏻",
                "fr": "TEsst Après Pip Pip Yalah, le combat pour l’écologie 🌳 mais surtout pour la tech made in 🇲🇦 continue !\nJ’ai co-fondé Popcard pour apporter aux professionnels une alternative aux cartes de visite en papier mais surtout pour booster leurs business. \nPopcard vous aide à centraliser votre univers digital, piloter votre activité commercial et contrôler votre image de marque. \n\nContactez-moi pour plus d’informations 👋🏻",
                "ar": "بعد Pip Pip Yalah، يستمر النضال من أجل البيئة 🌳 ولكن الأهم من ذلك من أجل التكنولوجيا المصنوعة في 🇲🇦! لقد شاركت في تأسيس Popcard لتقديم بديل للبطاقات الورقية للمحترفين، والأهم من ذلك لتعزيز أعمالهم. تساعدك Popcard على تنظيم عالمك الرقمي، وإدارة نشاطك التجاري، والتحكم في صورتك العلامية.\nتواصل معي لمزيد من المعلومات 👋🏻",
                "_id": "669f8879c9132c03b09610f4"
            },
            "professional_email": "otman@getpopcard.com",
            "recruitment_email": "",
            "temporary_password": "123123123",
            "confirm_email_token": "",
            "confirmed": true,
            "company": {
                "en": "@Popcard",
                "fr": "@Popcard",
                "ar": "@Popcard",
                "_id": "669e4464cd3ebe58682f5b3a"
            },
            "phone": [
                {
                    "prefix": "212",
                    "number": "601505420",
                    "_id": "669f87a1c9132c03b0960ffc",
                    "createdAt": "2024-07-23T10:36:17.955Z",
                    "updatedAt": "2024-07-23T10:36:17.955Z"
                }
            ],
            "website": "www.getpopcard.com",
            "language": "fr",
            "title": {
                "en": "CEO & Cofounder",
                "fr": "CEO & Cofounder",
                "ar": "الرئيس التنفيذي والمؤسس المشارك",
                "_id": "669e4464cd3ebe58682f5b3b"
            },
            "address": "Technopark, Aïn Chock, Casablanca, Prefecture of Casablanca, Casablanca-Settat, Maroc",
            "cover": {
                "url": "collaborator-Otman-HARRAK-1707439378297.jpeg",
                "x": 45,
                "y": -152,
                "zoom": 90,
                "_id": "65c5751f60428af5ac0a5ec6"
            },
            "profile_picture": "profile-4gHjS9vUVweHTLydDlJRas20k-1715881899548.png",
            "element_order": [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8
            ],
            "onboarding_step": 10,
            "appointment": "",
            "socials": [
                {
                    "url": "www.linkedin.ma",
                    "identifier": "455862ca-81e3-43b3-b185-2a8d1abb6f01",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f78e",
                        "label": "Linkedin",
                        "icon": "linkedin.svg",
                        "notoriety_order": 5,
                        "__v": 0
                    },
                    "is_admin": true,
                    "checkSocial": false,
                    "_id": "668e5906bf4ee27d1929f6cb"
                },
                {
                    "url": "212-601505420",
                    "identifier": "f123b968-f694-4406-84f9-23cfcf1df118",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f784",
                        "label": "Whatsapp",
                        "icon": "whatsapp.svg",
                        "notoriety_order": 3,
                        "__v": 0
                    },
                    "is_admin": false,
                    "checkSocial": false,
                    "_id": "668e5906bf4ee27d1929f6cc"
                },
                {
                    "url": "33-666975005",
                    "identifier": "49b05ef9-3e5a-49a9-a564-41d92aa0d462",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f784",
                        "label": "Whatsapp",
                        "icon": "whatsapp.svg",
                        "notoriety_order": 3,
                        "__v": 0
                    },
                    "is_admin": false,
                    "checkSocial": false,
                    "_id": "668e5906bf4ee27d1929f6cd"
                },
                {
                    "url": "https://www.instagram.com/get.popcard/",
                    "identifier": "d9e267a6-4ea5-4281-8640-3cdda5c32687",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f78f",
                        "label": "Instagram",
                        "icon": "instagram.svg",
                        "notoriety_order": 4,
                        "__v": 0
                    },
                    "is_admin": false,
                    "checkSocial": false,
                    "_id": "668e5906bf4ee27d1929f6ce"
                },
                {
                    "url": "https://www.instagram.com/h.otman/",
                    "identifier": "a1cc1a11-4a31-43b3-82a4-3d81d6dc1f58",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f78f",
                        "label": "Instagram",
                        "icon": "instagram.svg",
                        "notoriety_order": 4,
                        "__v": 0
                    },
                    "is_admin": false,
                    "checkSocial": false,
                    "_id": "668e5906bf4ee27d1929f6cf"
                }
            ],
            "links": [
                {
                    "type": "url",
                    "thumb": "d716287f71b3cdaebafe187437733b1e.png",
                    "url": "https://getpopcard.com/comment-ca-marche",
                    "title": {
                        "en": "How it works?",
                        "fr": "Comment ça marche?",
                        "ar": "كيف تعمل؟",
                        "_id": "669e4464cd3ebe58682f5b3e"
                    },
                    "description": {
                        "en": "3 easy steps to share your contact details without contact",
                        "fr": "3 étapes simples pour partager vos coordonnées sans contact",
                        "ar": "3 خطوات بسيطة لمشاركة تفاصيل الاتصال الخاصة بك دون اتصال",
                        "_id": "669e4464cd3ebe58682f5b3f"
                    },
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b3d"
                },
                {
                    "type": "url",
                    "thumb": "fe5cbf8a0e65cfb49303952ff18d2cf3.png",
                    "url": "https://medias24.com/2022/10/04/popcard-une-carte-de-visite-intelligente-centree-sur-les-besoins-des-professionnels/",
                    "title": {
                        "en": "Popcard on Medias24",
                        "fr": "Popcard sur Medias24",
                        "ar": "Popcard على Medias24",
                        "_id": "669e4464cd3ebe58682f5b41"
                    },
                    "description": {
                        "en": "Popcard, an intelligent business card focused on the needs of professionals",
                        "fr": "Popcard, une carte de visite intelligente centrée sur les besoins des professionnels",
                        "ar": "Popcard، بطاقة عمل ذكية تركز على احتياجات المحترفين",
                        "_id": "669e4464cd3ebe58682f5b42"
                    },
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b40"
                }
            ],
            "images": [
                {
                    "image": "files-1661468965765.jpeg",
                    "title": "Image 5",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6d6"
                },
                {
                    "image": "files-1661468965868.jpeg",
                    "title": "Image 46",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6d7"
                },
                {
                    "image": "files-1661468965924.jpeg",
                    "title": "Image 47",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6d8"
                },
                {
                    "image": "files-1661468965961.jpeg",
                    "title": "Image 53",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6d9"
                },
                {
                    "image": "files-1661468965980.jpeg",
                    "title": "Image 12",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6da"
                },
                {
                    "image": "files-1661468966000.jpeg",
                    "title": "Image 13",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6db"
                },
                {
                    "image": "files-1661468966021.jpeg",
                    "title": "Image 14",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6dc"
                },
                {
                    "image": "files-1661468966028.jpeg",
                    "title": "Image 15",
                    "is_admin": false,
                    "_id": "668e5906bf4ee27d1929f6dd"
                }
            ],
            "products": [
                {
                    "title": {
                        "en": "Standard popcard (Last name, first name and company name)",
                        "fr": "Popcard standard (Nom, prénom et nom d’entreprise)",
                        "ar": "بطاقة البوب القياسية (الاسم الأخير والاسم الأول واسم الشركة)",
                        "_id": "669e4464cd3ebe58682f5b4a"
                    },
                    "price": {
                        "currency": "MAD",
                        "amount": 390,
                        "_id": "669e4464cd3ebe58682f5b4b"
                    },
                    "pictures": [
                        "f2c293269e4dd148bda41607ebd80af2.jpeg",
                        "2d8a8d2dcd8ee6cfa245b5afd5696625.jpeg"
                    ],
                    "links": [
                        {
                            "store": {
                                "_id": "64066931621af85e8b8c230b",
                                "label": "Autre",
                                "icon": "/assets/logo/autre",
                                "__v": 0
                            },
                            "url": "https://getpopcard.com/commande-popcard-pro",
                            "_id": "669e4464cd3ebe58682f5b4c"
                        }
                    ],
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b49"
                },
                {
                    "title": {
                        "en": "Personalized popcard (Customization of the standard + Logo + choice of printing color: White, Silver or Gold)",
                        "fr": "Popcard personnalisée (Personnalisation de la standard + Logo + choix de la couleur d’impression : Blanc, Silver ou Gold)",
                        "ar": "بطاقة بوب كارد مخصصة (تخصيص المعيار + الشعار + اختيار لون الطباعة: أبيض، فضي أو ذهبي)",
                        "_id": "669e4464cd3ebe58682f5b4e"
                    },
                    "price": {
                        "currency": "MAD",
                        "amount": 490,
                        "_id": "669e4464cd3ebe58682f5b4f"
                    },
                    "pictures": [
                        "a19956f1d4dec1956996a901f0d6926f.jpeg"
                    ],
                    "links": [
                        {
                            "store": {
                                "_id": "64066931621af85e8b8c230b",
                                "label": "Autre",
                                "icon": "/assets/logo/autre",
                                "__v": 0
                            },
                            "url": "https://getpopcard.com/commande-popcard-pro",
                            "_id": "669e4464cd3ebe58682f5b50"
                        }
                    ],
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b4d"
                }
            ],
            "videos": [
                {
                    "type": "1",
                    "url": "https://youtu.be/RrP6R8_nEKc",
                    "description": {
                        "en": "Welcome to the future. Welcome to Popcard.",
                        "fr": "Welcome to the future. Welcome to Popcard. ",
                        "ar": "مرحبا بكم في المستقبل. مرحبا بكم في بوبكارد. ",
                        "_id": "669e4464cd3ebe58682f5b44"
                    },
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b43"
                },
                {
                    "type": "1",
                    "url": "https://youtu.be/uA5jsf3cTEY",
                    "description": {
                        "en": "Passage 2M - Who wants to invest in my project",
                        "fr": "Passage 2M - Qui veut invetir dans mon projet",
                        "ar": "Passage 2M - من يريد الاستثمار في مشروعي",
                        "_id": "669e4464cd3ebe58682f5b46"
                    },
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b45"
                },
                {
                    "type": "1",
                    "url": "https://youtu.be/RCRjR-eSXk4",
                    "description": {
                        "en": "Info passage evening on 2M",
                        "fr": "Passage Info soir sur 2M",
                        "ar": "مرور المعلومات المسائية على 2M",
                        "_id": "669e4464cd3ebe58682f5b48"
                    },
                    "is_admin": false,
                    "_id": "669e4464cd3ebe58682f5b47"
                }
            ],
            "notes": "Migration",
            "user_token": "e9e029971d9fc23d80d8579e6b8952ac",
            "form": true,
            "active": true,
            "createdAt": "2022-07-03T02:47:44.000Z",
            "updatedAt": "2024-11-19T15:35:41.967Z",
            "backofficeEmptySocials": [
                {
                    "url": "",
                    "identifier": "69d54329-400d-4654-a95b-ad22dfc82089",
                    "social_id": {
                        "_id": "637235ad60136a9a3e26f78e",
                        "label": "Linkedin",
                        "icon": "linkedin.svg",
                        "notoriety_order": 5,
                        "__v": 0
                    },
                    "is_admin": true,
                    "checkSocial": false,
                    "_id": "65e1072b5f860aaeee073ca2"
                }
            ],
            "__v": 2,
            "pdfs": [],
            "currentProfile": null,
            "isMultiProfile": true,
            "profiles": [
                {
                    "_id": "63c01d6c1cacf0ea1dc64f19",
                    "first_name": "Otman",
                    "last_name": "HARRAK",
                    "title": {
                        "en": "CEO & Cofounder",
                        "fr": "CEO & Cofounder",
                        "ar": "الرئيس التنفيذي والمؤسس المشارك",
                        "_id": "669e4464cd3ebe58682f5b3b"
                    },
                    "profile_picture": "profile-4gHjS9vUVweHTLydDlJRas20k-1715881899548.png"
                },
                {
                    "_id": "673517acaf53ff4e51ef835c",
                    "first_name": "Otman",
                    "last_name": "HARRAK",
                    "title": {
                        "en": "Founder",
                        "fr": "Fondateur",
                        "ar": "مؤسس",
                        "_id": "673518abaf53ff4e51ef8416"
                    },
                    "profile_picture": "685e974d-7bf5-4b4e-aea6-f39abbd9b4d9.jpeg",
                    "socials": [
                        {
                            "social_id": {
                                "_id": "637235ad60136a9a3e26f78e",
                                "label": "Linkedin",
                                "icon": "linkedin.svg",
                                "notoriety_order": 5,
                                "__v": 0
                            }
                        }
                    ],
                    "products": []
                }
            ]
        }
    }
}

const ProfileLocked = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [code, setCode] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isInputTypePass, setIsInputTypePass] = useState(true)

    // API calls
    const [checkCollabIfLocked] = useCheckCollabIfLockedMutation()

    useEffect(() => {
        let timeoutId;
        if (error) {
            timeoutId = setTimeout(() => {
                setError(false);
            }, 2500);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [error]);

    const handleChange = (e) => {
        setCode(e.target.value)
        if (error) setError(false)
    }

    const handleSubmit = () => {
        setLoading(true)
        checkCollabIfLocked({
            id,
            content: { password: code }
        }).unwrap()
            .then((res) => {
                console.log('res: ', res);
                dispatch(getCollaborator({ ...res.data.collaborator, skipRefeshCollab: true, }));
                navigate(`/profile/${res.data.collaborator.collaborator_id}`, { replace: true });
            })
            .catch((err) => { setError(true); toast.error(err.data.message.fr ? err.data.message.fr : 'Une erreur est survenue, veuillez réessayer.') })
            .finally(() => { setLoading(false) })


        // Create a state in redux to save a boolean value to check if other get should fetch


        // if the code is correct, i will redirect to the profile page
        // navigate(`/profile/4gHjS9vUVweHTLydDlJRas20k`, { replace: true }); //change the id to res.id
        // if the code is incorrect, i will show an error message

    }
    return (
        <>
            <div className='min-h-screen flex items-center justify-center backdrop-blur-lg font-SteradianRegular'>
                <div className='absolute top-0 left-[50%] max-w-[580px] w-full h-full bg-black bg-opacity-50 -z-10 backdrop-blur-lg transform translate-x-[-50%]'>
                    {/* <img src='/assets/img/holders/otmanProfile.png' alt='blured profile' className='w-full h-full object-fill' /> */}
                    <img src='/assets/img/holders/profileBlured.png' alt='blured profile' className='w-full h-full object-fill' />
                </div>
                <div className='absolute top-0 left-0 h-full min-h-screen backdrop-blur-sm w-full' />
                <div className='bg-white p-8 rounded-xl shadow-xl max-w-[480px] w-full z-30 mx-4'>
                    <div>
                        <h1 className='font-SteradianMedium text-center mb-7'>Merci de saisir le code pour accéder à ce profil.</h1>

                        <div className='relative'>
                            <input
                                type={isInputTypePass ? 'password' : 'text'}
                                className={`border  p-2 rounded-lg w-full mt-4 transition-all duration-3000 ${error ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder='Code' value={code} onChange={handleChange}
                            />

                            <div className='absolute top-[50%] right-2' onClick={() => setIsInputTypePass(!isInputTypePass)}>
                                {!isInputTypePass ? (
                                    <RiEyeOffLine className="text-[#795656]" />
                                ) : (
                                    <RiEyeLine className="text-[#795656]" />
                                )}
                            </div>
                        </div>

                        <div className='flex justify-center items-center mt-6'>
                            <div className=' text-white bg-black rounded-md text-[16px] py-3 px-5 cursor-pointer' onClick={handleSubmit}>
                                {loading ? 'Chargement...' : 'Envoyer'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileLocked