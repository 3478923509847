import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import { getCollaborator } from "../redux/appSlices/collaborator/collaboratorSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetCollaboratorQuery } from "../redux/appSlices/collaborator/collaboratorApiSlice";
// import usePwaNavigation from "../hooks/usePwaNavigation";
function MainLoading() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // usePwaNavigation();
  const [onboardingStep, setOnboardingStep] = useState(null);

  const onboarding_step = null;

  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);

  const { data, isLoading } = useGetCollaboratorQuery(id, { skip: collaborator?.skipRefeshCollab });

  useEffect(() => {
    if (data) {
      // if (data.data.collaborator.backoffice_id._id === "6662f77e71f25fdcf00dae65"){
      //   window.location.href = "https://www.instagram.com/iqos.mar";
      // }
      dispatch(getCollaborator(data.data.collaborator));
    }
    if (onboarding_step) {
      setOnboardingStep(onboarding_step);
    } else if (!onboardingStep && !isLoading) {
      setOnboardingStep(data?.data?.collaborator?.onboarding_step);
    }
  }, [data]);

  //some profiles need to reffers to others
  const [customId, setCustomId] = useState({
    U1tsS5lENooFs2eF6p: "IW41PCB3HRXSIHcyny",
    "8C9XEb0J1PpDF5sPxh": "4MFSgGV5zaFqu9vXow",
  });

  const collaborator_redirections = (id) => {
    if (Object.keys(customId).includes(id)) {
      return customId[id];
    } else {
      return id;
    }
  };

  useEffect(() => {
    if (!id) {
      return navigate(`/login`, { replace: true });
    }

    if (data?.data?.locked) {
      return navigate(`/locked/${id}`, { replace: true });
    }
    if (data?.data?.collaborator?.backoffice_id?._id === "6662f77e71f25fdcf00dae65") {
      window.location.href = "https://www.instagram.com/iqos.mar";
    }
    else if (onboardingStep === 10) {
      navigate(`/profile/${collaborator_redirections(id)}`, { replace: true });
    } else if (onboardingStep !== null) {
      navigate(`/onboarding/${id}`, { replace: true });
    } else {
    }

    // if (onboardingStep === 10) {
    //   navigate(`/profile/${collaborator_redirections(id)}`, { replace: true });
    // } else if (onboardingStep !== null) {
    //   navigate(`/onboarding/${id}`, { replace: true });
    // } else {
    // }
  }, [onboardingStep]);

  if (!data && !isLoading) {
    window.location.href = "https://www.ocpgroup.ma/";
  }

  if (data && !data?.data?.collaborator?.active && !data?.data?.locked) {
    window.location.href = "https://www.ocpgroup.ma/";
  }

  return <Loading />;
}

export default MainLoading;
